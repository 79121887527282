import React from 'react';

import { useState, useEffect } from 'react';
import { useParams, useLocation } from "react-router-dom";

import Lottie from 'react-lottie-player'

import bimbang_logo from '../assets/logo/bimbang.svg';
import luisa_logo from '../assets/logo/luisa_basilio.svg';
import shakishaki_logo from '../assets/logo/shakishaki.svg';
import yaffa_logo from '../assets/logo/yaffa.svg';

function LunaMenu() {

	var { shop_name } = useParams();

	var shops = {
		'poissonniere' : '8HoktHoS1bSdXx4vfXWc',
		'courcelles' : 'Bj5RV9oVnxvopInqcvYl'
	};

	var query_brands = new URLSearchParams(useLocation().search).get('brands');
	if (query_brands === null) query_brands = 'shaki_shaki,yaffa,bim_bang,nok_noi,luisa_basilio,drink';
	query_brands = query_brands.split(',');

	const [shop, setShop] = useState({});
	const [luna_products, setLunaProducts] = useState({});

	const [i, setI] = useState(0);
	const [menu, setMenu] = useState({});


	useEffect(() => {

		setInterval(function () {
			setI(i + 1);
		}, 60000);

	}, []);


	useEffect(() => {

		var xmlhttp = new XMLHttpRequest();
		xmlhttp.onreadystatechange = function() {
			if (this.readyState == 4 && this.status == 200) {
				setMenu(JSON.parse(this.responseText));
			}
		};

		xmlhttp.open('GET', 'https://firebasestorage.googleapis.com/v0/b/shakishaki-243509.appspot.com/o/menu%2F' + shops[shop_name] + '.json?alt=media', true);
		xmlhttp.send();

	}, [i]);


	const Menu = (data) => {

		//if (remote_config.brands === undefined) return null;
		//var main_color = remote_config.brands[data.brand].main_color;

		var list = [];
		for (var i in menu.data) {
			if (menu.data[i].key === data.brand) {
				list = menu.data[i].categories;
				var main_color = menu.data[i].main_color;
			}
		}

		if (list.length === 0) return null;

		return (

			<div style={{ margin: '5vw', marginBottom: '5vw', border: 'solid', borderWidth: 1, borderColor: main_color }} >

				<div style={{ backgroundColor: main_color, padding: '2vw' }}>
					{data.brand === 'shaki_shaki' &&
						<img src={shakishaki_logo} style={{ height : '6vw' }} alt=''/>
					}
					{data.brand === 'luisa_basilio' &&
						<img src={luisa_logo} style={{ height : '6vw' }} alt=''/>
					}
					{data.brand === 'yaffa' &&
						<img src={yaffa_logo} style={{ height : '6vw' }} alt=''/>
					}
					{data.brand === 'bim_bang' &&
						<img src={bimbang_logo} style={{ height : '7vw' }} alt=''/>
					}
				</div>

				<div style={{ padding: 20 }}>

						{list.map((category, i) => (
							<div key={'category_' + i}>

								<div style={{ marginBottom: 5, borderBottom: 'solid', borderBottomWidth: '0.3vw', borderColor: main_color, textTransform: 'uppercase', fontFamily: 'Bebas Neue', fontSize: '4vw' }}>
									{category.name}
								</div>

								<div style={{ display : 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: 20 }}>
								{category.products.map((product, j) => (
									<div key={product.id} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '47%', fontFamily: 'Barlow Condensed', fontSize: '2.8vw' }}>

										<div style={{ width: '75%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: product.sold_out ? '#666' : '#000' }}>
											{product.name}
										</div>

										{(!product.sold_out && product.old_price !== undefined) &&
											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div style={{ textDecorationLine: 'line-through', marginRight: 5, color: '#999' }}>
													{product.old_price.toFixed(2)}€
												</div>
												<div>{product.price.toFixed(2)}€</div>
											</div>
										}

										{(!product.sold_out && product.old_price === undefined) &&
											<div>{product.price.toFixed(2)}€</div>
										}

										{product.sold_out &&
											<div style={{ color: '#666' }} >
												épuisé
											</div>
										}

									</div>
								))}
								</div>
							</div>
						))}

				</div>

			</div>
		);
	}

	return (
		<>
			{query_brands.map((brand, i) => (
				<Menu key={brand} brand={brand} />
			))}
			<Lottie
				autoplay
				loop
				src={require('../assets/lottie/shiba.json')}
				style={{ position: 'absolute', bottom: 0, left: '-10vw', height: '20vw', width: '20vw', transform: [{rotateY: '180deg'}] }}
			/>
		</>
	);
}

export default React.memo(LunaMenu);



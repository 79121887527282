import firebase from "firebase/compat/app"

import logo from './logo.svg';
import './App.css';

import Nav from './Nav';

function App() {

	const app = firebase.initializeApp({
		apiKey: "AIzaSyBYTXFbp_p3fV9O_u0wXWMHkVtXNTGPUbk",
		authDomain: "shakishaki-243509.firebaseapp.com",
		databaseURL: "https://shakishaki-243509.firebaseio.com",
		projectId: "shakishaki-243509",
		storageBucket: "shakishaki-243509.appspot.com",
		messagingSenderId: "200499339726",
		appId: "1:200499339726:web:b17d31c6274981c4e74b9f",
		measurementId: "G-JSQPM27NYL"
	});

	return (
		<>
			<Nav/>
		</>
	);
}

export default App;

import React from 'react';
import { useState, useContext } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

import LunaMenu from './components/LunaMenu';

const Nav = () => {

	return (
		<BrowserRouter>
			<div>
				<Routes>

					<Route path="menu/:shop_name" element={<LunaMenu />} />

				</Routes>
			</div>
		</BrowserRouter>
	);

}

export default React.memo(Nav);